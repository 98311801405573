.Sec6_main_div {
  width: 100vw;
  height: 100%;
  padding: 3rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Sec6_sub_div {
  width: 78%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
}

.Sec6_sub_div > h1 {
  font-size: 35px;
  font-family: "Be Viettnam Pro Bold";
}

.main_form_div {
}
.form_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.name_div_main {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.fname_div,
.text_input_div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.fname_div > label,
.text_input_div > label {
  font-size: 16px;
  font-family: "Be Viettnam Pro Regular";
}
.fname_input_div {
  width: 350px;
  height: 30px;
  border: 1px solid #737373;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.fname_input_div > input {
  width: 95%;
  height: fit-content;
  font-size: 16px;
  border: none;
  outline: none;
  background: transparent;
  font-family: "Be Viettnam Pro Regular";
}
.text_area_input_box {
  width: 720px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #737373;
  border-radius: 6px;
}
.text_area_input_box > textarea {
  resize: none;
  width: 98%;
  height: 90%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: "Be Viettnam Pro Regular";
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.errors_msg_p {
  color: #ff0000;
  font-size: 14px;
  font-family: "Be Viettnam Pro Regular";
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #ffffff00;
}

@media screen and (max-width: 1024px) {
  .name_div_main {
    flex-direction: column;
  }
  .text_area_input_box {
    width: 350px;
    height: 140px;
  }
}
@media screen and (max-width: 350px) {
  .name_div_main {
    flex-direction: column;
  }
  .text_area_input_box {
    width: 300px;
    height: 140px;
  }
  .fname_input_div {
    width: 300px;
  }
}
@media screen and (max-width: 767px) {
}
