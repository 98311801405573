.Sec2_main_div {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0rem;
}
.Sec2_sub_div {
  width: 78%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
}

.ABout_txt_sub {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 80%;
}
.about_us_text > p {
  text-align: center;
  font-size: 18px;
  font-family: "Be Viettnam Pro Regular";
}
.Sec2_sub_div > h1 {
  font-size: 35px;
  font-family: "Be Viettnam Pro Bold";
}
.about_us_text > h2 {
  text-align: center;
  font-size: 25px;
  font-family: "Be Viettnam Pro SemiBold";
}

@media screen and (max-width: 768px) {
  .Sec2_sub_div {
    width: 100%;
  }
  .ABout_txt_sub {
    width: 90%;
  }
  .Sec2_sub_div > h1 {
    width: 90%;
    font-size: 25px;
    text-align: center;
  }
  .about_us_text > h2 {
    font-size: 20px;
    text-align: center;
  }
}
