.Sec4_main_div {
  width: 100vw;
  height: 100%;
  padding: 3rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Sec4_sub_div {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
}
.Sec4_sub_div > h1 {
  font-size: 35px;
  font-family: "Be Viettnam Pro Bold";
}

.content_div {
  /* display: grid;
  grid-template-columns: 35% 35% 35%;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.content_img {
  width: 700px;
  height: 500px;
  object-fit: cover;
}
.content_img > video {
  width: 100%;
  height: 100%;

  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.cotent_text_div {
  width: 50%;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
}

.contect_innetr_text_idv {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.text_sec4_inner {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.text_sec4_inner > h2 {
  font-size: 20px;
  font-family: "Be Viettnam Pro Bold";
}
.text_sec4_inner > p {
  font-size: 14px;
  font-family: "Be Viettnam Pro Regular";
}

@media screen and (max-width: 1024px) {
  .content_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .content_img {
    width: 70%;
    height: 70%;
  }
}

@media screen and (max-width: 850px) {
  .content_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .cotent_text_div {
    width: 80%;
    height: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .content_img {
    width: 70%;
    height: 70%;
  }
}

@media screen and (max-width: 600px) {
  .content_div {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .cotent_text_div {
    width: 90%;
    height: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
  .content_img {
    width: 70%;
    height: 70%;
  }
  .content_img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .Sec4_sub_div > h1 {
    font-size: 25px;
    text-align: center;
    width: 90%;
  }
}
