.new_main_div {
  width: 100%;
  height: 100%;
  padding: 3rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new_sub_div {
  width: 78%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
}
.new_sub_div > h1 {
  font-size: 35px;
  font-family: "Be Viettnam Pro Bold";
}
@media screen and (max-width: 768px) {
  .new_sub_div > h1 {
    font-size: 30px;
  }
  .new_sub_div {
    width: 100%;
  }
}
