* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-sec {
  width: 100vw;
  height: 100vh;

  background: linear-gradient(180deg, #99dfec 0%, #e6c7c0 50%, #c5dbe2 100%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 2rem;
}

.text img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  filter: drop-shadow(0px 8px 8px white);
}

.text h1 {
  color: #ffffff;
  font-family: "DM Sherif Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 46px;
  line-height: 58px;
  text-shadow: 1px 1px 2px #000000;
}

.text p {
  color: #ffffff;
  font-family: "Be Viettnam Pro Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  text-shadow: 1px 1px 2px #000000;
}

.button_back {
  background-color: whitesmoke;
  color: #7fc4dc;
  padding: 10px 30px;
  border: none;
  border-radius: 20px;
  font-size: 17px;
  font-family: "Be Viettnam Pro Medium";
  font-style: normal;
  font-weight: 300;
  filter: drop-shadow(0px 4px 4px #99dfec);
  text-decoration: none;
}

.button_back:hover {
  transition: all 1s ease-in;
  background-color: #7fc4dc;
  color: white;

  font-family: "Be Viettnam Pro Medium";
  font-style: normal;
  font-weight: 300;
  filter: drop-shadow(0px 20px 8px #e6c7c0);
}
