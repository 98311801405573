.main_footer {
  width: 100vw;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 1rem 0rem;
}
.sub_footer {
  width: 78%;
  height: 80%;
  /* background-color: aqua; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex-direction: column;
}

.F_content_div {
  width: 100%;
  height: 50%;
  border-bottom: 1px solid #747474;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: bisque; */
}
.trem_and_privacy_div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.trem_and_privacy_div > span {
  font-size: 15px;
  font-family: "Be Viettnam Pro Medium";
  cursor: pointer;
}
.social_icon_div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
.links {
  width: 30px;
  height: 30px;
  background-color: aliceblue;
  display: grid;
  place-items: center;
  color: black;
  border-radius: 6px;
}
.links:hover {
  border-radius: 6px;
  background-color: #9edde9;
  color: #050eb6;
}
.icons {
  width: 100%;
  aspect-ratio: 3/3;
  object-fit: contain;
}

@media screen and (max-width: 1024px) {
  .F_content_div {
    width: 100%;
    height: 50%;
    border-bottom: 1px solid #747474;
    display: flex;
    align-items: center;
    justify-content: space-between;

    /* background-color: bisque; */
  }
}

@media screen and (max-width: 768px) {
  .F_content_div {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #747474;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 10px;
  }
}
