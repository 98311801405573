.Sec1_main_div {
  width: 100vw;
  height: 700px;
  background: url("../../../../assets/Sec1_bg.png");
  background-position: center;
  /* background-size: 100% 100%; */
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 101px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Sec1_sub_div {
  width: 80%;
  height: 70%;
  display: grid;
  place-items: center;
  grid-template-columns: 0% 100%;
}
.text_Div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  /* background-color: #f4f4f47b; */
  border-radius: 8px;
  width: 90%;
  height: 100%;
  background-color: #ffffff3b;
  /* backdrop-filter: blur(1.6px); */
}

.text_Div > h1 {
  font-size: 40px;
  font-family: "Alverata_headings";
  /* font-family: "Be Viettnam Pro Bold"; */
  /* color: #f2f8f6; */
  /* text-shadow: 1px 1px 2px #747474; */
  text-transform: capitalize;
  text-align: center;
}
.text_Div > h1 > span {
  font-family: "Alverata_reguler";
  font-weight: 600;
  /* text-shadow: 1px 1px 2px #393939; */
  /* text-shadow: rgb(82, 82, 82) 1px 0 10px; */
}
.text_Div > h5 {
  font-size: 20px;
  font-family: "Alverata_reguler";
  /* text-shadow: 1px 1px 2px #747474; */
}
.text_Div > p {
  width: 70%;
  text-align: center;
  font-size: 15px;
  font-family: "Alverata_reguler";
  /* text-shadow: 1px 1px 2px #747474; */
}

.mainbutton {
  padding: 10px 20px;
  color: #414f4c;
  background: #f2f8f6;
  border: 1px solid #fff;
  border-radius: 20px;
  font-family: "Be Viettnam Pro SemiBold";
  cursor: pointer;
}
.mainbutton:hover {
  background: linear-gradient(
    180deg,
    rgba(153, 223, 236, 1) 0%,
    rgba(230, 199, 192, 1) 50%,
    rgba(197, 219, 226, 1) 100%
  );
  transition: all 0.3s ease-in;
}

@media screen and (max-width: 1024px) {
  .text_Div {
    width: 90%;
  }
  .text_Div > h1 {
    font-size: 30px;
  }
  .text_Div > p {
    width: 80%;
  }
}
@media screen and (max-width: 850px) {
  .text_Div {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .Sec1_main_div {
    background-size: cover;
  }
  .Sec1_sub_div {
    width: 95%;
    height: 90%;
    display: grid;
    grid-template-columns: 0% 100%;
  }
  .text_Div > h1 {
    font-size: 25px;
  }
  .text_Div > h5 {
    width: 70%;
    font-size: 16px;
    text-align: center;
  }
  .text_Div > p {
    width: 70%;
    text-align: center;
    font-size: 13px;
    font-family: "Alverata_reguler";
  }
  .text_Div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    height: 90%;
    width: 100%;
  }
}
