.Sec5_main_div {
  width: 100%;
  height: 100%;
  padding: 3rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Sec5_sub_div {
  width: 78%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
}

.Sec5_sub_div > h1 {
  font-size: 35px;
  font-family: "Be Viettnam Pro Bold";
}

.sub_Sec5_div {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 2rem 0rem;
}

.Sec5_img_div {
  width: 100%;
  height: 100%;
}
.Sec5_img_div > img {
  width: 100%;
  aspect-ratio: 3/3;
  object-fit: contain;
}
.sec5_text_main_div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.sec5_text_main_div > h3 {
  font-size: 25px;
  font-family: "Be Viettnam Pro Bold";
}
.sec5_text_main_div > ul > li {
  font-size: 20px;
  font-family: "Be Viettnam Pro Bold";
}
.sec5_text_main_div > p {
  font-size: 18px;
  font-family: "Be Viettnam Pro Regular";
}
@media screen and (max-width: 850px) {
  .Sec5_sub_div {
    width: 90%;
  }
  .sub_Sec5_div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0rem;
  }
}

@media screen and (max-width: 600px) {
  .Sec5_sub_div {
    width: 90%;
  }
  .sub_Sec5_div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0rem;
  }
  .sec5_text_main_div > h1 {
    font-size: 28px;
    font-family: "Be Viettnam Pro Bold";
  }
  .sec5_text_main_div > p {
    font-size: 12px;
    font-family: "Be Viettnam Pro Regular";
  }
  .sec5_text_main_div > ul > li {
    font-size: 16px;
  }
  .Sec5_sub_div > h1 {
    font-size: 25px;
  }
}
